import React from 'react'

const FAQs = () => {
  return (
    <div className='container box-small'>
        <h1 className="green-text">Getting Started</h1>
        <div id="FAQ" className="box-small">
            <h2 className="orange dark-bg">
                Bridging to Polygon
            </h2>
            <h4 className="green-text">In order to bridge from INKz to PINKz</h4>
            <h4 className="green-text">Click the "Bridge" tab in the navigation bar at the top.</h4>
            <h4 className="green-text">Make sure you are on the "Ethereum" network.</h4>
            <h4 className="green-text">Enter the amount you would like to transfer and click the pink "Bridge" button.</h4>
            <h4 className="green-text">The bridging proccess takes about 2 minutes, but in rare cases can take up to 15 minutes.</h4>
            <h4 className="green-text">If you have any issues bridging please open a ticket in our discord.</h4>
        </div>
        <div className="box-small">
            <h2 className="orange dark-bg">
                The auction
            </h2>
            <h4 className="green-text">Each bid adds to the final price.</h4>
            <h4 className="green-text">When the timer falls below 5 minutes each bid will reset the timer to 5 minutes.</h4>
            <h4 className="green-text">When the auction is over, the highest bidder is the winner of the auction.</h4>
            <h4 className="green-text">The winner must pay the final price to claim the prize.</h4>
        </div>
        <div className="box-small">
            <h2 className="orange dark-bg">
                The allowlist market
            </h2>
            <h4 className="green-text">To purchase a whitelist spot, first make sure you have the required inkz "on the Polygon network" to purchase.</h4>
            <h4 className="green-text">Simply click purchase and complete the transaction.</h4>
            <h4 className="green-text">You are only allowed to purchase 1 whitelist spot per wallet!</h4>
            <h4 className="green-text"> In order to be sure your whitelist was purchased successfully the purchase button should turn grey and read "Already Purchased"</h4>
        </div>
    </div>
  )
}

export default FAQs