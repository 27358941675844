import React, { useState} from 'react'
import Countdown from 'react-countdown';
import { Typography } from "@mui/material";
import { ethers } from 'ethers';
import InkzLogoL2 from '../images/inkz-L2.gif'
import {buyWhitelist} from './Interact'




export const WhitelistCard = ({whitelist, whitelistId, address, purchases, whitelistedAddresses}) => {
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(null)

    var currentTimeInMilliseconds=Date.now();
    const stringyWhitelist = whitelistedAddresses[whitelistId].toString()
    const formattedWhitelist = stringyWhitelist.split(",").join("\n")

    const buyButtonPressed = async () => {
        setError(null);
        setStatus(null);
        const response = await buyWhitelist(whitelistId, address.wallet);
        setStatus(response.status);
        setError(response.error);
    }

  return (
    <div className='card whitelist-card'>
        <div>
        <img src={whitelist.image} alt={whitelist.name} className='whitelist-image'/>
        </div>
        <div className='card'>

            <div className='card content-item'>
                {whitelist.name}
            </div>
            <div className='card address-card'>
                Price
            </div>
            <div className='card address'>
            <img src={InkzLogoL2} className='inkz-image' alt="inkz"/> {ethers.utils.formatUnits(whitelist.price, 18)} 
            </div>
            <div className='card address-card'>
                Remaining
            </div>
            <div className='card address'>
                {whitelist.slots - whitelist[0].length}
            </div>
            {(currentTimeInMilliseconds < whitelist.endTime * 1000)?(<div>
                <Countdown className='counter' date={whitelist.endTime * 1000}/>
            </div>):(<div className='text'>Whitelist Concluded</div>)}
            
            {(purchases[whitelistId] === "0" && currentTimeInMilliseconds < whitelist.endTime * 1000) ? (
                <button className='purchase-button' onClick={buyButtonPressed}>Purchase</button>
            ) : (null)}
            {(purchases[whitelistId] === "1") ? (<div className="text"><h3>Already Purchased</h3></div>) : (null)}
            {error ? (
            <div className='status'>
              <Typography variant="h6" color="red">
                {error}
              </Typography>
              </div>
            ) : null}
            {status ? (
                <div className='status'>
              <Typography variant="h6" color="green">
                {status}
              </Typography>
              </div>
            ) : null}
            <div>
              <h3 className='orange-underline'>Allow List</h3>
              <p className='small-text'>{formattedWhitelist}</p>
            </div>  
        </div>
    </div>
  )
}
export default WhitelistCard;