import React, { useState} from 'react'
import truncateEthAddress from 'truncate-eth-address'
import Countdown from 'react-countdown';
import { Typography } from "@mui/material";
import { ethers } from 'ethers';
import InkzLogoL2 from '../images/inkz-L2.gif'
import inkzBurn from '../images/burn.gif'
import {makeBid, claimWinner} from './Interact'




export const AuctionCard = ({auction, auctionId, address}) => {
    const [error, setError] = useState(null)
    const [status, setStatus] = useState(null)
    
    const zeroAddr = "0x0000000000000000000000000000000000000000"
    var currentTimeInMilliseconds=Date.now();

    const bidButtonPressed = async () => {
        setError(null);
        setStatus(null);
        const response = await makeBid(auctionId, address);
        setStatus(response.status);
        setError(response.error);
    }

    const claimButtonPressed = async () => {
        setError(null);
        setStatus(null);
        const response = await claimWinner(auctionId, address)
        setStatus(response.status);
        setError(response.error);
    }


  return (
    <div className='card auction-card'>
        <div>
        <img src={auction.image} alt={auction.name} className='auction-image'/>
        {(auction.winner !== zeroAddr) ? ( <div className='claimed text-winner'>🏆 Winner Claimed 🏆</div>) : (null)}
        </div>
        <div className='card'>

            <div className='card content-item'>
                {auction.name}
            </div>
            <div className='card address-card'>
                Highest bidder
            </div>
            {(auction.highestBidder.toUpperCase() === address.toUpperCase())?(<div className='card'>
              <h3 className="highest">
              🎉 You!
              </h3>
            </div>):(<div className='card'>
              <h3>
                  {truncateEthAddress(auction.highestBidder)}
              </h3>
            </div>)}
            <div className='card address-card'>
                Current price
            </div>
            <div className='card address'>
            <img src={InkzLogoL2} className='inkz-image' alt="inkz"/> {ethers.utils.formatUnits(auction.highestBid, 18)} 
            </div>
            {(currentTimeInMilliseconds < auction.auctionEndTime * 1000)?(<div>
                <Countdown className='counter' date={auction.auctionEndTime * 1000}/>
            </div>):(<div className='text'>Auction Concluded</div>)}
            
            {(currentTimeInMilliseconds < auction.auctionEndTime * 1000 && address.toUpperCase() !== auction.highestBidder.toUpperCase()) ? (
                <button className='bid-button' onClick={bidButtonPressed}>Bid {auction.bidIncrement / 1000000000000000000} <img src={InkzLogoL2} alt="inkz" className='inkz-image'/></button>
            ) : (null)}
            {(auction.highestBidder.toUpperCase() === address.toUpperCase() && (currentTimeInMilliseconds > auction.auctionEndTime * 1000) && (auction.winner === zeroAddr)) ? (<button className='green-button' onClick={claimButtonPressed}>🏆 claim 🏆</button>) : (null)}
            {error ? (
            <div className='status'>
              <Typography variant="h6" color="red">
                {error}
              </Typography>
              </div>
            ) : null}
            {status ? (
                <div className='status'>
              <Typography variant="h6" color="green">
                {status}
              </Typography>
              </div>
            ) : null}
            <div className='card address counter'>
            <img src={inkzBurn} alt="inkz"/> {ethers.utils.formatUnits(auction.spilled, 18)} 
            </div>
            
            
        </div>
    </div>
  )
}
export default AuctionCard;