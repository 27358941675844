import React from 'react'
import WhitelistCard from './WhitelistCard'

const WhitelistContainer = ({whitelists, whitelistId, address, purchases, whitelistedAddresses}) => {
  return (
    <div className='whitelist-container'>
        {whitelists.map(( whitelist, whitelistId) => {
            return <WhitelistCard whitelist={whitelist} key={whitelistId} address={address} whitelistId={whitelistId} purchases={purchases} whitelistedAddresses={whitelistedAddresses} />
        })}
    </div>
  )
}

export default WhitelistContainer