import React from "react";
import { useEffect, useState } from "react";
import {
    whitelistContract,
    getAllWhitelists,
} from './Interact';
import WhitelistContainer from "./WhitelistContainer";



const Whitelist = (wallet) => {
    const [status, setStatus] = useState("");
    const [whitelists, setWhitelists] = useState([])
    const [purchases, setPurchases] = useState([])
    const [whitelistedAddress, setWhitelistAddress] = useState([])
    const [whitelistId, setWhitelistId] = useState(null)
    const [whitelistPurchase, setWhitelistPurchase] = useState(1)
    const [dataSynced, setDataSyced] = useState(false)

    useEffect(() => {
      getWhitelistData();
      // eslint-disable-next-line
    }, [whitelistPurchase, wallet])
    

    useEffect(() => {
      if (wallet) {
        function addWhitelistListener() {
          if (wallet) {
          whitelistContract.events.whitelistPurchased({})
        .on('connected', function(subscriptionId){ console.log("whitelist listener sub ID: " + subscriptionId);})
        .on('data', function(event){ setWhitelistPurchase(whitelistPurchase => whitelistPurchase + 1);})
        }
      else {
        console.log("wallet address not set!!")
      }}
      addWhitelistListener();
        
      }}, [wallet]);
    
    const getWhitelistData = async () => {
      if (wallet) {
        const info = [];
        const whitelistIds = [];
        const whitelistedAddresses = [];
        let allWhitelists = await getAllWhitelists();
        await new Promise((resolve, reject) => setTimeout(resolve, 1000));
        const max = allWhitelists.length;
        let i = 0;
        while (i < max){
          info[i] = allWhitelists[i]
          var allowlisted = allWhitelists[i].allPurchases.includes(wallet.wallet)
          if(allowlisted){
            purchases[i] = "1";
          } else {
            purchases[i] = "0";
          }
          whitelistedAddresses[i] = allWhitelists[i].allPurchases;
          whitelistIds[i] = i;
          i++;
        }
        setWhitelists(info)
        setWhitelistId(whitelistIds)
        setPurchases(purchases)
        setWhitelistAddress(whitelistedAddresses)
        setDataSyced(true)
      } else {
        console.log("Please connect wallet to view whitelists")
        setStatus("Please connect wallet to view whitelists")
      }
    }

    return (
        <div className="main">
            <div className="status-bar">
                <div className="text">{status}</div>
            </div>

            <div className='text-l'>
            Allow List
            </div>

        {dataSynced ? (<WhitelistContainer whitelists={whitelists} whitelistId={whitelistId} address={wallet} purchases={purchases} whitelistedAddresses={whitelistedAddress} />) : (null)}
        </div>

    )
}

export default Whitelist;