import React from "react";
import { useEffect, useState } from "react";
import {
    auctionContract,
    getContractOwner,
    getAllAuctions,
    connectWallet
} from './Interact';
import AuctionContainer from "./AuctionContainer"
import LoadAuctionPanel from "./OwnerPanel";
import Whitelist from "./Whitelist";
import Web3 from 'web3'
import AuctionImg from '../images/Marketplace---LGV2.gif'


const Auction = () => {
    const [status, setStatus] = useState("");
    const [auctions, setAuctions] = useState([])
    const [auctionId, setAuctionId] = useState(null)
    const [contractOwner, setContractOwner] = useState("")
    const [newBid, setNewBid] = useState(1)
    const [wallet, setWallet] = useState("")
    const [networkID, setNetworkID] = useState("")
    console.log(networkID)


    //const location = useLocation()
    //const { from, network } = location.state

    useEffect(() => {
      function addWalletListener() {
        if (window.ethereum) {
          window.ethereum.on("accountsChanged", (accounts) => {
            if (accounts.length > 0) {
              setWallet(accounts[0]);
            } else {
              setWallet("");
            }
          });
        } else {
          setStatus(
"no wallet connected @ auction"
          );
        }
      } addWalletListener()
    }, [wallet]);

    useEffect(() => {
      connectWalletPressed()
  }, [wallet]);
  
  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setWallet(Web3.utils.toChecksumAddress(walletResponse.address));
    setNetworkID(walletResponse.chain);
  }



    //console.log("connected wallet address: " + wallet)

    useEffect(() => {
      if (wallet) {
      function addAuctionClaimListener() {
        auctionContract.events.AuctionEnded({})
      .on("connected", function(subscriptionId){ console.log("claim listener sub ID: " + subscriptionId);})
      .on('data', function(event){ setNewBid(newBid => newBid + 1);})
      }
      addAuctionClaimListener();
        return () => {
          console.log("claim listener unmounted")
          // This code runs when the component unmounts
        }
      }}, [wallet]);

      useEffect(() => {
        if (wallet) {
        function addAuctionBidListener() {
          auctionContract.events.HighestBidIncrease({})
        .on("connected", function(subscriptionId){ console.log("bid listener sub ID: " + subscriptionId);})
        .on('data', function(event){ setNewBid(newBid => newBid + 1);})
        }
        addAuctionBidListener();
          return () => {
            //console.log("bid listener unmounted")
            // This code runs when the component unmounts
          }
        }}, [wallet]);

    useEffect(() => {
      const getAuctionData = async () => {
        if (wallet) {
          const info = [];
          const auctionIds = [];
          //const counter = await auctionContract.methods.auctionCounter().call();
          const allAuctions = await getAllAuctions()
          //const max = (parseInt(counter) + 1).toString();
          let i = 0;
          while (i < allAuctions.length){
            info[i] = allAuctions[i]
            auctionIds[i] = i;
            i++;
          }
          setAuctions(info)
          setAuctionId(auctionIds)
          setStatus("")
        } else {
          console.log("Please connect wallet to view marketplace")
          setStatus("Please connect wallet to view marketplace")
        }
      }
      getAuctionData();
        return () => {
          //console.log("auction data unmounted")
          // This code runs when the component unmounts
        }
      }, [newBid, wallet]);



    useEffect(() => {
      const getOwner = async () => {
        if (wallet) {
          const owner = await getContractOwner()
          setContractOwner(owner)
        } else {
          console.log("Must be connected to get owner address")
        }
      }
      getOwner();
        return () => {
          //console.log("auction data unmounted")
          // This code runs when the component unmounts
        }
      }, [wallet]);

    //console.log("connected network: " + network.networkID)


    return (
      
        <div>
          <img className="cover-image" src={AuctionImg} alt="Auctionimg"/>
            <div className="status-bar">
                <div className="text">{status}</div>
            </div>
            <div className='welcome-bar'>
            <div className='text-l'>
            INKz Auction
            </div>
          </div>
          {wallet ? (<AuctionContainer auctions={auctions} auctionId={auctionId} address={wallet}></AuctionContainer>) : (null)}
        
        {(wallet.toUpperCase() === contractOwner.toUpperCase() && wallet !== "") ? (<LoadAuctionPanel />) : (null)}
        {wallet ? (<Whitelist wallet={wallet} />) : (null)}
        </div>
    )
}

export default Auction;