import React from 'react'
import AuctionCard from './AuctionCard'

const AuctionContainer = ({auctions, auctionId, address}) => {
  return (
    <div className='auction-container'>
        {auctions.map(( auction, auctionId ) => {
            return <AuctionCard auction={auction} key={auctionId} address={address} auctionId={auctionId} />
        })}
    </div>
  )
}

export default AuctionContainer